<template>
    <div class="row">
        <template v-if="dispute">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pb-3">
                <div class='card shadow-lg'>
                    <div class='card-header d-print-none'>
                        <h3>{{ dispute.dispute_type }} Dispute</h3>
                        <div class="d-flex">

                            <button v-if="dispute.active" class="btn btn-danger me-2"
                                @click="showModal('Close Dispute', 'Are you sure you want to close the dispute?', 'Yes', 'No', () => DisableDispute(dispute.id))">Close
                                dispute</button>
                            <button v-else class="btn btn-success me-2"
                                @click="showModal('Re-Open Dispute', 'Are you sure you want to re-open the Dispute?', 'Yes', 'No', () => EnableDispute(dispute.id))">Re-Open
                                dispute</button>

                            <button class="btn btn-success ms-auto me-2" @click="downloadPDF()">Download PDF</button>
                            <button class="btn btn-info " @click="emailDispute()">Re-Send Email PDF</button>
                        </div>
                    </div>
                    <div class='card-body'>

                        <div class='row'>
                            <div class='col-12'>
                                <h4><strong>Reference: </strong>{{ $filters.reference(dispute.id) }}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <h4><strong>Date Of Lodgement: </strong>{{ $filters.date(dispute.date_created) }}</h4>
                            </div>
                            <div class="col-6">
                                <h4><strong>Date Of Initial Complaint: </strong>{{
                                    $filters.date(dispute.initial_date_of_dispute) }}</h4>
                            </div>
                        </div>
                        <hr>

                        <div class="d-none d-print-block">
                            <Legislation height="auto" />
                            <hr>
                        </div>

                        <h4 style="font-weight: bold; page-break-before: always;">Declaration</h4>
                        <p class="d-none d-print-block">
                            NOTE: The Dispute form will remain hidden until you confirm that
                            you have ready and understood the legislation above.
                            By selecting Yes you acknowledge that you have read
                            and understood the legislation.
                        </p>
                        <h5>Legislation Read and Understood?
                            <strong>Yes</strong> / <s>No</s>
                        </h5>
                        <hr>

                        <table id="personal info" class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">ELM Account Holder Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Full Name</th>
                                    <td>{{ dispute.account.first_name }} {{ dispute.account.last_name }}</td>
                                </tr>
                                <tr>
                                    <th>Phone Number</th>
                                    <td>
                                        <span v-if="dispute.account.primary_contact">{{ dispute.account.primary_contact
                                            }}</span>
                                        <span v-if="dispute.account.alternative_contact"><br>{{
                                            dispute.account.alternative_contact }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{{ dispute.account.email }}</td>
                                </tr>
                                <tr>
                                    <th>ELM Account Number</th>
                                    <td>{{ dispute.account.municipal_account_number }}</td>
                                </tr>
                                <tr v-if="dispute.statement">
                                    <th>ELM Statement</th>
                                    <td>
                                        <a style="text-decoration: underline;" class="link-primary"
                                            :href="dispute.statement" download="statement.pdf">
                                            View Statement
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="dispute.proof_of_payment">
                                    <th>ELM Proof of Payment</th>
                                    <td>
                                        <a style="text-decoration: underline;" class="link-primary"
                                            :href="dispute.proof_of_payment" download="proof_of_payment.pdf">
                                            View Proof of Payment
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Physical Address</th>
                                    <td>
                                        {{ dispute.account.street_number }}<br>
                                        {{ dispute.account.street_name }}<br>
                                        {{ dispute.account.suburb.suburb }}<br>
                                        {{ dispute.account.town.town }}<br>
                                        {{ dispute.account.suburb.postcode }}<br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr>

                        <table id="dispute criteria" class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">Dispute Criteria</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Selected the reason/s for lodging this Billing Dispute</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Amount Disputed</th>
                                    <td>{{ $filters.price(dispute.amount_disputed) }}</td>
                                </tr>
                                <tr>
                                    <th>Comment</th>
                                    <td>
                                        <p style="white-space: pre;">{{ dispute.reason }}</p>
                                    </td>
                                </tr>
                            </tbody>

                        </table>


                        <div id="embeds" class="d-print-none">
                            <hr>
                            <h5>View Statement</h5>
                            <embed :src="dispute.statement" style="height: 400px; width: 100%; padding-bottom: 20px;" />
                            <h5>View Proof of Payment</h5>
                            <embed :src="dispute.proof_of_payment" style="height: 400px; width: 100%; " />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-print-none">
                <div class='card'>
                    <div class='card-header'>
                        <h3>Comments</h3>
                    </div>
                    <div class='card-body'>
                        <template v-for="comment in dispute.comments">
                            <div id="comment">
                                <div style="background-color: #f6f6f6;">
                                    <hr>
                                    <p>
                                        Posted By <span>{{ comment.account }}</span>
                                        on {{ $filters.dateTime(comment.date_created) }}
                                    </p>
                                    <hr>
                                </div>
                                <div>
                                    <p style="white-space: pre;">{{ comment.comment }}</p>
                                </div>
                                <hr>
                            </div>
                        </template>
                    </div>
                    <div class='card-footer'>
                        <form @submit.prevent="CreateComment">
                            <label class="mb-2" for="comment">Reply to dispute</label>
                            <textarea v-model="comment" type="text" class="form-control" aria-describedby="descHelp"
                                rows="8" maxlength="1000"></textarea>
                            <br>
                            <button class="btn btn-primary w-100">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            Dispute Not Found
        </template>
    </div>
</template>

<style scoped>
.link {
    text-decoration: underline;
    color: #0d6efd;
    cursor: pointer;
}

h5 {
    font-size: medium;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { showModal } from '../../components/customModal.js'
import Legislation from './../Core/Legislation.vue'
import { useToast } from 'vue-toastification'
import { readOnlyQuill } from '@/components/quillManager'

const html2pdf = require('html2pdf.js')

const toast = useToast();
let store = useStore();
let route = useRoute();

let id = route.params.id || undefined;

let dispute = ref(undefined);
let comment = ref(undefined);
let pdf = ref(undefined)
let print = ref(false)

onMounted(async () => {
    await getDispute();
})

async function getDispute() {
    const response = await store.dispatch(
        'get',
        store.state.account && store.state.account.is_staff ? `disputes/detail/${id}/` : `disputes/detail/public/${id}/`
    );
    console.log(response.data);
    if (response.status === 200) {
        dispute.value = response.data;
    }
}

async function CreateComment() {
    if (!comment.value) {
        toast.error('Please fill in a comment before continuing', {
            timeout: 3000
        });
    }

    const payload = {
        api: `disputes/comment/${id}/`,
        data: {
            comment: comment.value
        }
    }

    const response = await store.dispatch('post', payload);

    if (response.status == 201) {
        comment.value = '';
        window.scrollTo(0, 0);
        getDispute();
    }
}

async function DisableDispute(id) {
    let reason = prompt("Please provide reason for closing the dispute", "")
    if (reason == null || reason == "") { return }
    if (id) {
        const payload = {
            api: `disputes/disable/${route.params.id}/`,
            data: { 'comment': reason }
        };

        const response = await store.dispatch('post', payload);

        if (response.status === 200) {
            await getDispute();
        }
    }
}

async function EnableDispute(id) {
    let reason = prompt("Please provide reason for re-opening dispute", "")
    if (reason == null || reason == "") { return }
    console.log(reason);
    if (id) {
        const payload = {
            api: `disputes/enable/${route.params.id}/`,
            data: { 'comment': reason }
        };

        const response = await store.dispatch('post', payload);

        if (response.status === 200) {
            await getDispute();
        }
    }
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

async function downloadPDF() {
    const response = await store.dispatch('download', `disputes/pdf/${route.params.id}/`);

    if (response.status === 200) {
        // Create blob from response data
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `dispute_${route.params.id}.pdf`; // Set filename

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}

async function emailDispute() {
    const get_response = await store.dispatch('get', `disputes/pdf/${route.params.id}/`);
    if (get_response.status === 200) {
        const response = await store.dispatch('put', {
            api: `disputes/email/${route.params.id}/`,
            data: {}
        });
        if (response.status === 200) {
            toast.success('Email sent', {
                timeout: 5000
            });
            return
        }
        toast.error(`Error sending email: ${response.data}`, {
            timeout: 5000
        });
    }
    else {
        toast.error(`Error sending email: ${get_response.data}`, {
            timeout: 5000
        });
    }
}

</script>